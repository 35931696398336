import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@/models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { updateMethod } from './classification-rule.api';
import { buildHttpHost } from '@/util';
import { environment } from '@environment';

@Injectable()
export class UserApi {
  baseUrl = 'users';

  constructor(private http: HttpClient) {}

  getUsers(page: number, workId?: number, departmentId?: number) {
    const params = {
      page: page.toString(),
      ...(workId ? { workId: `${workId}` } : {}),
      ...(departmentId ? { departmentId: `${departmentId}` } : {}),
    };
    return this.http.get<User[]>(`${this.baseUrl}`, { params, observe: 'response' });
  }

  get(userId: number) {
    return this.http.get<User>(`users/${userId}`);
  }

  me() {
    return this.http.get<User>('users/me');
  }

  addUser(user: User): Observable<User> {
    return this.http.post<User>(`${this.baseUrl}`, { ...user });
  }
  updateUser(id: number, user): Observable<User> {
    return this.http.put<User>(`${this.baseUrl}/${id}`, { ...user });
  }
  deleteUser(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
  updateLoginInfo(id: number, user): Observable<User> {
    if (!user.loginId) {
      delete user.loginId;
    }
    return this.http.put<User>(`${this.baseUrl}/${id}/login_info`, { ...user });
  }

  getFileUrl() {
    return `${buildHttpHost(environment)}/${this.baseUrl}/csv`;
  }
  uploadFile(file, method: updateMethod) {
    const headers = new HttpHeaders().append('x-meta-no-change-content-type', 'true');
    const formData = new FormData();
    formData.append('file', file);
    if (method === 'overwrite') {
      return this.http.put(`${this.baseUrl}`, formData, { headers });
    } else if (method === 'add') {
      return this.http.post(`${this.baseUrl}`, formData, { headers });
    }
  }
}
