import { Action, props, createAction, createReducer, on } from '@ngrx/store';
import { LaborReport } from '@/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const setMakingLaborReport = createAction(
  '[LaborReport] Set MakingLaborReport',
  props<{ makingLaborReport: LaborReport }>()
);

export const setTodayLaborReports = createAction(
  '[LaborReport] Set setTodayLaborReports',
  props<{ todayLaborReports: LaborReport[] }>()
);

export interface State {
  makingLaborReport: LaborReport;
  todayLaborReports: LaborReport[];
}
export const initialState = {
  makingLaborReport: null,
  todayLaborReports: null,
};

export const laborReportReducer = createReducer(
  initialState,
  on(setMakingLaborReport, (state, { makingLaborReport }) => {
    return {
      ...state,
      makingLaborReport,
    };
  }),
  on(setTodayLaborReports, (state, { todayLaborReports }) => {
    return {
      ...state,
      todayLaborReports,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return laborReportReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('laborReport');
export const getMakingLaborReport = createSelector(selectFeature, (state: State) => state.makingLaborReport);
export const getTodayLaborReports = createSelector(selectFeature, (state: State) => state.todayLaborReports);
