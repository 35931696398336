import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppState } from '@/store';
import { setTodayLaborReports } from '@/store/labor-report.store';
import { LaborReportApi } from '@/apis';
import { LaborReport } from '@/models';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class LaborReportService {
  constructor(private store: Store<AppState>, private api: LaborReportApi) {}

  createLaborReport(laborReport: LaborReport): Observable<LaborReport> {
    return this.api.createLaborReport(laborReport);
  }

  todayLaborReports() {
    return this.api.todayLaborReports().pipe(
      tap(todayLaborReports => {
        this.store.dispatch(setTodayLaborReports({ todayLaborReports }));
      })
    );
  }

  updateTodayLaborReports() {
    return this.api.updateTodayLaborReports().pipe(
      tap(todayLaborReports => {
        this.store.dispatch(setTodayLaborReports({ todayLaborReports }));
      })
    );
  }

  fetchLaborReport(laborReportId: number) {
    return this.api.fetchLaborReport(laborReportId);
  }

  updateLaborReport(laborReportId: number, laborReport) {
    return this.api.updateLaborReport(laborReportId, laborReport);
  }

  deleteLaborReport(laborReportId: number) {
    return this.api.deleteLaborReport(laborReportId);
  }

  fetchLaborReports(userId, range) {
    return this.api.fetchLaborReports(userId, range);
  }
}
