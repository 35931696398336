import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { Tenant } from '@/models';

@Injectable()
export class TenantApi {
  constructor(private http: HttpClient) {}

  currentTenant() {
    return this.http.get<Tenant>('tenants/current');
  }
}
