import * as moment from 'moment-timezone';
import 'moment-duration-format';

moment.locale('ja');
moment.tz.setDefault('Asia/Tokyo');
moment.tz.zone('Asia/Tokyo');

function generateDateSeries(
  begin: moment.Moment,
  end: moment.Moment,
  amount: moment.Duration = moment.duration(1, 'day')
): moment.Moment[] {
  const dates = [];
  let curr = begin.clone();

  if (!begin.isBefore(end)) {
    throw new Error('Argument Error. `begin` must before `end`');
  }

  while (curr.isBefore(end)) {
    dates.push(curr.clone());
    curr = curr.add(amount);
  }

  return dates;
}

export { moment, generateDateSeries };
