<div class="root">
  <ion-content class="nav">
    <div class="nav-logo">
      <img src="./assets/logo_dark.svg" alt="働き方レポート" />
    </div>
    <div *ngFor="let group of appPages" class="nav-group">
      <div class="nav-group-title">
        <ion-icon *ngIf="group.icon" [src]="group.icon" class="icon"></ion-icon>
        <div class="text">{{ group.title }}</div>
      </div>
      <div class="nav-group-pages">
        <ng-container *ngFor="let page of group.pages">
          <ng-container *ngIf="page.url; else Handler">
            <a
              class="nav-group-pages-item"
              [routerLink]="page.url"
              routerLinkActive="current"
              [routerLinkActiveOptions]="page.routerLinkActiveOptions ? page.routerLinkActiveOptions : {}"
              [queryParamsHandling]="page.queryParamsHandling"
            >
              <div class="nav-group-pages-item-inner">
                <ion-icon *ngIf="page.icon" [src]="page.icon" class="icon"></ion-icon>
                <div class="text">{{ page.title }}</div>
              </div>
            </a>
          </ng-container>
          <ng-template #Handler>
            <a class="nav-group-pages-item" (click)="page.behavior()">
              <div class="nav-group-pages-item-inner">
                <ion-icon *ngIf="page.icon" [src]="page.icon" class="icon"></ion-icon>
                <div class="text">{{ page.title }}</div>
              </div>
            </a>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ion-content>
  <div class="main">
    <ion-router-outlet></ion-router-outlet>
  </div>
</div>
