import { Injectable } from '@angular/core';
import { WorkLogApi } from './work-log.api';
import { moment } from '@/libs/moment';
import { DateRange } from '@/models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WorkTimeApi {
  constructor(private logApi: WorkLogApi) {}

  getWorkTime(userId: string, range: DateRange) {
    return this.logApi.getWorkTimeTotalByUser(userId, range).pipe(
      map(t => {
        const hour = Math.round(moment.duration(t.sum, 'minutes').asHours() * 10) / 10;
        const formatted = moment.duration(t.sum, 'minutes').format('h', 1);
        const judge = this.workTimeJudge(hour, t.workDays);

        return {
          workTime: {
            hour,
            formatted,
            judge,
          },
          overTime: {
            hour: t.overTimeDuration.asHours(),
            formatted: t.overTimeDuration.format('h', 1),
            judge: this.overTimeJudge(t.overTimeDuration.asHours(), t.workDays),
          },
          pcWorkDays: {
            days: t.pcWorkDays,
            judge: this.pcWorkTimeJudge(t.pcWorkDays, t.workDays),
          },
        };
      })
    );
  }

  workTimeJudge(hour: number, workDays: number) {
    const safeHours = 8 * workDays;
    const overHours = 10 * workDays;

    return hour <= safeHours ? 'good' : hour <= overHours ? 'normal' : 'bad';
  }

  overTimeJudge(hour: number, workDays: number) {
    const safeHours = 1 * workDays;
    const overHours = 2 * workDays;

    return hour <= safeHours ? 'good' : hour <= overHours ? 'normal' : 'bad';
  }

  pcWorkTimeJudge(days: number, workDays: number) {
    const safeDays = workDays;
    const overDays = 5 + workDays;

    return days <= safeDays ? 'good' : days <= overDays ? 'normal' : 'bad';
  }
}
