import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DateRange } from '@/models';

@Injectable({ providedIn: 'root' })
export class HolidayApi {
  constructor(private http: HttpClient) {}

  list(range: DateRange): Observable<{ [day: string]: string }> {
    const params = {
      beginDate: range.begin.format('YYYY-MM-DD'),
      endDate: range.end.format('YYYY-MM-DD'),
    };
    return this.http.get<any>('holidays', { params });
  }
}
