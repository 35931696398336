import { Action, props, createAction, createReducer, on } from '@ngrx/store';
import { Category } from '@/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const setCategories = createAction('[Category] Set Categories', props<{ categories: Category[] }>());
export const addCategory = createAction('[Category] Add Category', props<{ category: Category }>());
export const updateCategory = createAction('[Category] Update Category', props<{ id: number; category: Category }>());
export const deleteCategory = createAction('[Category] Delete Category', props<{ id: number }>());

export interface State {
  categories: { [id: number]: Category };
  totalPages: number;
}
export const initialState = {
  categories: null,
  totalPages: null,
};

export const categoryReducer = createReducer(
  initialState,
  on(setCategories, (state, { categories }) => {
    const newCategories = {};
    categories.forEach(category => {
      newCategories[category.id] = category;
    });
    return {
      ...state,
      categories: newCategories,
    };
  }),
  on(addCategory, (state, { category }) => {
    const categories = { ...state.categories };
    categories[category.id] = category; // id must be Infinity;
    return {
      ...state,
      categories,
    };
  }),
  on(updateCategory, (state, { id, category }) => {
    const categories = { ...state.categories };
    categories[id] = category;
    return {
      ...state,
      categories,
    };
  }),
  on(deleteCategory, (state, { id }) => {
    const categories = { ...state.categories };
    delete categories[id];
    return {
      ...state,
      categories,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return categoryReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('category');
export const getCategories = createSelector(selectFeature, (state: State) => {
  if (state.categories === null) {
    return null;
  }
  return Object.values(state.categories).sort((a, b) => b.id - a.id);
});
