import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Attendance } from '@/models';
import { environment } from '@environment';
import { buildHttpHost } from '@/util';
import { moment } from '@/libs/moment';

@Injectable({ providedIn: 'root' })
export class AttendanceApi {
  baseUrl = 'attendances';

  constructor(private http: HttpClient) {}

  createAttendance(attendance) {
    return this.http.post<Attendance>(`${this.baseUrl}`, { ...attendance });
  }

  todayAttendance() {
    return this.http.get<Attendance>(`${this.baseUrl}/today`);
  }

  yesterdayAttendance() {
    return this.http.get<Attendance>(`${this.baseUrl}/yesterday`);
  }

  updateAttendance(attendanceId, attendance) {
    return this.http.put<Attendance>(`${this.baseUrl}/${attendanceId}`, { ...attendance });
  }

  fetchAttendances(userId, range) {
    const params = {
      userId: userId + '',
      beginDate: moment(range.begin).format('YYYY-MM-DD'),
      endDate: moment(range.end).format('YYYY-MM-DD'),
    };
    return this.http.get<Attendance[]>(`${this.baseUrl}/users/${userId}`, { params });
  }
}
