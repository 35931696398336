import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '@environment';
import { IonicStorageModule } from '@ionic/storage';
import { ComponentsModule } from '@/components/components.module';
import { MobileComponentsModule } from '@/components/mobile/mobile-components.module';
import { DirectivesModule } from '@/directives/directives.module';
import { PipesModule } from '@/pipes/pipes.module';
import { ApisModule } from '@/apis/apis.module';
import { ServicesModule } from '@/services/services.module';
import { LayoutsModule } from '@/layouts/layouts.module';

import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from '@/http-interceptors';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers } from '@/store';

import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ReactiveFormsModule } from '@angular/forms';

import { SessionService } from '@/services';
import { AuthGuard } from '@/guards/auth/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEchartsModule } from 'ngx-echarts';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  SatDatepickerModule,
  SatNativeDateModule,
} from 'saturn-datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MOMENTJS_RANGE_DATE_FORMAT } from '@/config';

export function jwtOptionsFactory(injector) {
  return {
    tokenGetter: () => injector.get(SessionService).getIdToken(),
    whitelistedDomains: [`${environment.api.host}:${environment.api.port}`],
    blacklistedRoutes: [
      `${environment.api.host}:${environment.api.port}/api/public`,
      `${environment.api.host}:${environment.api.port}/api/private/sign_in`,
    ],
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    MobileComponentsModule,
    DirectivesModule,
    PipesModule,
    ApisModule,
    ServicesModule,
    LayoutsModule,
    HttpClientModule,
    IonicStorageModule.forRoot({ name: 'NrmContentManagerLocalStorageDb' }),
    StoreModule.forRoot(reducers, { metaReducers }),
    environment.production === undefined || environment.production
      ? []
      : StoreDevtoolsModule.instrument({ maxAge: 50 }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Injector],
      },
    }),
    ReactiveFormsModule,
    NgxEchartsModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
  ],
  providers: [
    httpInterceptorProviders,
    StatusBar,
    Geolocation,
    SplashScreen,
    AuthGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MOMENTJS_RANGE_DATE_FORMAT },
    { provide: MAT_DATE_LOCALE, useValue: 'ja' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
