import { NgModule } from '@angular/core';
import { SecToMinPipe } from './sec-to-min/sec-to-min.pipe';
import { MicrosecToMinPipe } from './microsec-to-min/microsec-to-min.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { FormatDatePipe } from './format-date/format-date.pipe';
import { FormatDateRangePipe } from './format-date-range/format-date-range.pipe';
import { MomentFormatPipe } from './moment-format/moment-format.pipe';
import { TrackByKeyPipe } from './track-by-key/track-by-key.pipe';
import { RangePipe } from './range/range.pipe';

@NgModule({
  declarations: [
    SecToMinPipe,
    MicrosecToMinPipe,
    SafeHtmlPipe,
    FormatDatePipe,
    FormatDateRangePipe,
    MomentFormatPipe,
    TrackByKeyPipe,
    RangePipe,
  ],
  imports: [],
  exports: [
    SecToMinPipe,
    MicrosecToMinPipe,
    SafeHtmlPipe,
    FormatDatePipe,
    FormatDateRangePipe,
    MomentFormatPipe,
    TrackByKeyPipe,
    RangePipe,
  ],
})
export class PipesModule {}
