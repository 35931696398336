import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WorkSituation } from '@/models';
import { environment } from '@environment';
import { buildHttpHost } from '@/util';

@Injectable({ providedIn: 'root' })
export class WorkSituationApi {
  baseUrl = 'work_situations';

  constructor(private http: HttpClient) {}

  getWorkSituations() {
    return this.http.get<WorkSituation[]>(`${this.baseUrl}`);
  }

  fetchWorkSituation(date, userId) {
    const params = { date };
    return this.http.get<WorkSituation>(`${this.baseUrl}/${userId}`, { params });
  }
}
