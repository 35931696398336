import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { PcUser } from '@/models';
import { PcUsersRelationship, PcUserListRow } from '@/models/pc-users-relationship';
import { buildHttpHost } from '@/util';
import { environment } from '@environment';

@Injectable()
export class PcUserApi {
  baseUrl = 'pc_users';
  relationUrl = 'pc_users_relationships';
  downloadCsvUrl = `${buildHttpHost(environment)}/${this.relationUrl}/csv`;

  constructor(private http: HttpClient) {}

  getPcUsers() {
    return this.http.get<PcUser[]>(`${this.baseUrl}`);
  }

  addPcUserRelationship(userId: number, pcUserId: number) {
    const params = {
      userId,
      pcUserId,
    };
    return this.http.post<PcUsersRelationship>(`${this.relationUrl}`, params);
  }
  deletePcUserRelationship(userId: number, pcUserId: number) {
    const params = {
      userId: `${userId}`,
      pcUserId: `${pcUserId}`,
    };
    return this.http.delete<void>(`${this.relationUrl}`, { params });
  }

  listUserRelationship() {
    return this.http.get<PcUserListRow[]>(`${this.relationUrl}/list`);
  }

  uploadPcUserRelationshipFile(file) {
    const headers = new HttpHeaders().append('x-meta-no-change-content-type', 'true');
    const formData = new FormData();
    formData.append('file', file);
    return this.http.put(`${this.relationUrl}/csv`, formData, { headers });
  }
}
