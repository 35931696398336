import { Component, OnInit } from '@angular/core';
import { SessionService, UserService } from '@/services';
import { moment } from '@/libs/moment';
import { Router } from '@angular/router';

export interface Page {
  title: string;
  url: string;
  icon?: string;
  routerLinkActiveOptions?: { exact: boolean };
  queryParamsHandling?: string;
}

export interface Handler {
  title: string;
  behavior: () => void;
  icon?: string;
}

export interface PageGroup {
  title: string;
  pages: (Page | Handler)[];
  icon?: string;
}

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  appPages: PageGroup[] = [];

  constructor(private sessionService: SessionService, private router: Router, private userSvc: UserService) {}

  async ngOnInit() {
    const user = await this.userSvc.me().toPromise();

    if (user.role === 'admin') {
      this.appPages.push(
        {
          title: 'カルテ',
          icon: './assets/home.svg',
          pages: [
            {
              title: 'ダッシュボード',
              url: '/dashboard/',
            },
            {
              title: 'チーム別PC稼働状況ログ',
              url: '/work-summary/teams',
            },
            {
              title: 'ユーザ別PC稼動状況ログ',
              url: `/work-summary/users`,
            },
            // {
            //   title: 'アプリ・ブラウザログ',
            //   url: '/browser-log/',
            // },
            // {
            //   title: '日報一覧',
            //   url: '/daily-report/',
            // },
          ],
        },
        {
          title: '設定',
          icon: './assets/home.svg',
          pages: [
            // {
            //   title: '企業情報',
            //   url: '/company-information/',
            // },
            {
              title: 'ユーザ管理',
              url: '/user-management/',
            },
            // {
            //   title: 'PC管理',
            //   url: '/pc-management/',
            // },
            {
              title: 'アプリルール管理',
              url: '/rule-management/',
            },
            // {
            //   title: '未分類ログ一覧',
            //   url: '/unclassified-logs/',
            // },
          ],
        }
      );
    }

    this.appPages.push({
      title: user.name,
      icon: './assets/person.svg',
      pages: [
        {
          title: 'マイページ',
          url: `/work-summary/users/${user.id}`,
        },
        {
          title: 'ログアウト',
          behavior: async () => {
            await this.logout().toPromise();
            this.router.navigateByUrl('/auth/login');
          },
        },
      ],
    });
  }

  logout() {
    return this.sessionService.signOut();
  }
}
