import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface SubmitParams {
  skipLoading?: boolean;
  loadingMessage?: string;
}

@Injectable()
export class FormService {
  constructor(private alertCtrl: AlertController, private loadingCtrl: LoadingController) {}

  async alertApiError(r: HttpErrorResponse, errorTitle?: string) {
    let obj;
    // rにはAPIレスポンス以外でのエラーも入ってくるのでチェックが必要
    if (r !== undefined && r.error !== undefined) {
      obj = r.error;
      let message = '';
      if (obj.error !== undefined && obj.error.error_details !== undefined) {
        message = obj.error.error_details.join('<br>');
      }
      const title = errorTitle;
      const alert = await this.alertCtrl.create({
        header: title,
        message,
        buttons: ['OK'],
      });
      alert.present();
    } else {
      const alert = await this.alertCtrl.create({
        header: 'エラー',
        buttons: ['OK'],
      });
      alert.present();
    }
  }

  async submit<T>(ob: Observable<T>, params?: SubmitParams): Promise<Observable<T>> {
    params = params || {};
    const loading = await this.loadingCtrl.create({
      message: params.loadingMessage || '送信中',
    });
    if (!params.skipLoading) {
      loading.present();
    }
    return ob.pipe(
      tap(
        _ => {
          if (!params.skipLoading) {
            loading.dismiss();
          }
        },
        e => {
          if (!params.skipLoading) {
            loading.dismiss();
          }
          this.alertApiError(e);
        }
      )
    );
  }
}
