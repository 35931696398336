import { Injectable } from '@angular/core';
import { SessionApi } from '@/apis';
import { Observable, timer, Subject, from } from 'rxjs';
import { tap, map, mergeMap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/config';
import { User } from '@/models';

@Injectable()
export class SessionService {
  constructor(private sessionApi: SessionApi, private storage: Storage) {}

  getIdToken(): Promise<string> {
    return this.storage.get(TOKEN_KEY);
  }
  signIn(tenantId: string, loginId: string, password: string): Observable<User> {
    return this.sessionApi.login(tenantId, loginId, password);
  }

  signOut() {
    return this.sessionApi.logout().pipe(tap(_ => window.location.reload()));
  }
}
