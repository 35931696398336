<ion-content>
  <div class="auth">
    <div class="auth-container">
      <div class="auth-container-cover">
        <img class="auth-container-cover-logo" src="./assets/logo_dark.svg" />
      </div>
      <div class="auth-container-body">
        <ion-router-outlet></ion-router-outlet>
      </div>
    </div>
  </div>
</ion-content>
