import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LaborReport } from '@/models';
import { environment } from '@environment';
import { buildHttpHost } from '@/util';
import { moment } from '@/libs/moment';

@Injectable({ providedIn: 'root' })
export class LaborReportApi {
  baseUrl = 'labor_reports';

  constructor(private http: HttpClient) {}

  createLaborReport(laborReport: LaborReport) {
    return this.http.post<LaborReport>(`${this.baseUrl}`, { ...laborReport });
  }

  todayLaborReports() {
    return this.http.get<LaborReport[]>(`${this.baseUrl}/today`);
  }

  updateTodayLaborReports() {
    return this.http.put<LaborReport[]>(`${this.baseUrl}/today/end_work`, {});
  }

  fetchLaborReport(laborReportId: number) {
    return this.http.get<LaborReport>(`${this.baseUrl}/${laborReportId}`);
  }

  updateLaborReport(laborReportId: number, laborReport) {
    return this.http.put<LaborReport>(`${this.baseUrl}/${laborReportId}`, { ...laborReport });
  }

  deleteLaborReport(laborReportId: number) {
    return this.http.delete<LaborReport>(`${this.baseUrl}/${laborReportId}`);
  }

  fetchLaborReports(userId, range) {
    const params = {
      userId: userId + '',
      beginDate: moment(range.begin).format('YYYY-MM-DD'),
      endDate: moment(range.end).format('YYYY-MM-DD'),
    };
    return this.http.get<LaborReport[]>(`${this.baseUrl}/users/${userId}`, { params });
  }
}
