import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@/directives/directives.module';
import { PipesModule } from '@/pipes/pipes.module';
import { ButtonComponent } from './button/button.component';
import { WysiwygBodyComponent } from './wysiwyg-body/wysiwyg-body.component';
import { SlidableSelectComponent } from './slidable-select/slidable-select.component';
import { WorkGraphUserComponent } from './work-graph-user/work-graph-user.component';
import { RouterModule } from '@angular/router';
import { TableColumnNumberComponent } from './table-columns/table-column-number/table-column-number.component';
import { TableColumnSelectComponent } from './table-columns/table-column-select/table-column-select.component';
import { TableColumnTextComponent } from './table-columns/table-column-text/table-column-text.component';
import { TableColumnOperationsComponent } from './table-columns/table-column-operations/table-column-operations.component';
import { PortalModule } from '@angular/cdk/portal';
import { ButtonSelectComponent } from './button-select/button-select.component';
import { TableColumnColorComponent } from './table-columns/table-column-color/table-column-color.component';
import { PaginationButtonsComponent } from './pagination-buttons/pagination-buttons.component';
import { TooltipComponent } from './common/tooltip.component';
import { FeedbackIconComponent } from './icon/feedback-icon.component';

@NgModule({
  declarations: [
    ButtonComponent,
    ButtonSelectComponent,
    WysiwygBodyComponent,
    SlidableSelectComponent,
    WorkGraphUserComponent,
    TableColumnNumberComponent,
    TableColumnSelectComponent,
    TableColumnTextComponent,
    TableColumnOperationsComponent,
    TableColumnColorComponent,
    PaginationButtonsComponent,
    TooltipComponent,
    FeedbackIconComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    DirectivesModule,
    PipesModule,
    PortalModule,
  ],
  exports: [
    ButtonComponent,
    ButtonSelectComponent,
    WysiwygBodyComponent,
    SlidableSelectComponent,
    WorkGraphUserComponent,
    TableColumnNumberComponent,
    TableColumnSelectComponent,
    TableColumnTextComponent,
    TableColumnOperationsComponent,
    TableColumnColorComponent,
    PaginationButtonsComponent,
    TooltipComponent,
    FeedbackIconComponent,
  ],
})
export class ComponentsModule {}
