// Meta Reducers
import { ActionReducer, MetaReducer, Action } from '@ngrx/store';

export const REMOVE_SESSION = '[Session] Remove session';

export class RemoveSession implements Action {
  readonly type = REMOVE_SESSION;
  constructor() {}
}
export type Actions = RemoveSession;

export function resetState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === REMOVE_SESSION) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer<any>[] = [resetState];
