import { Action, props, createAction, createReducer, on } from '@ngrx/store';
import { WorkSituation } from '@/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';
import { findLastIndex } from 'lodash';

export const setWorkSituations = createAction(
  '[WorkSituation] Set WorkSituations',
  props<{ workSituations: WorkSituation[] }>()
);

export const setWorkSituation = createAction(
  '[WorkSituation] Set WorkSituation',
  props<{ workSituation: WorkSituation }>()
);

export interface State {
  workSituations: { [id: number]: WorkSituation };
}
export const initialState = {
  workSituations: null,
};

export const workSituationReducer = createReducer(
  initialState,
  on(setWorkSituations, (state, { workSituations }) => {
    const newWorkSituations = {};
    workSituations.forEach(workSituation => {
      newWorkSituations[workSituation.id] = workSituation;
    });
    return {
      ...state,
      workSituations: newWorkSituations,
    };
  }),
  on(setWorkSituation, (state, { workSituation }) => {
    const newWorkSituations = { ...state.workSituations };
    newWorkSituations[workSituation.id] = workSituation;
    return {
      ...state,
      workSituations: newWorkSituations,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return workSituationReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('workSituation');
export const getWorkSituations = createSelector(selectFeature, (state: State) => {
  if (state.workSituations === null) {
    return null;
  }
  return Object.values(state.workSituations).map(workSituation => {
    let lastLaborReport;
    if (workSituation.laborReports) {
      const now = moment();
      lastLaborReport = workSituation.laborReports.find(laborReport => {
        return now.isBetween(laborReport.startAt, laborReport.endAt);
      });
    }
    return { ...workSituation, lastLaborReport };
  });
});
export const getWorkSituation = (id: number) => {
  return createSelector(selectFeature, (state: State) => {
    if (state.workSituations === null) {
      return null;
    }
    return state.workSituations[id];
  });
};
