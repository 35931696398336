import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { ClassificationRule } from '@/models';

export const setClassificationRules = createAction(
  '[ClassificationRule] Set classification rules',
  props<{ rules: ClassificationRule[] }>()
);
export const addClassificationRule = createAction(
  '[ClassificationRule] Add classification rule',
  props<{ rule: ClassificationRule }>()
);
export const updateClassificationRule = createAction(
  '[ClassificationRule] Update classification rule',
  props<{ id: number; rule: ClassificationRule }>()
);
export const deleteClassificationRule = createAction(
  '[ClassificationRule] Delete classification rule',
  props<{ id: number }>()
);
export const setClassificationTotalPages = createAction(
  '[ClassificationRule] Set classification total pages',
  props<{ totalPages: number }>()
);

export interface State {
  rules: { [id: number]: ClassificationRule };
  totalPages: number;
}

export const initialState = {
  rules: null,
  totalPages: null,
};

export const classificationRuleReducer = createReducer(
  initialState,
  on(setClassificationRules, (state, { rules }) => {
    const newRules = {};
    rules.forEach(rule => {
      newRules[rule.id] = rule;
    });
    return {
      ...state,
      rules: newRules,
    };
  }),
  on(addClassificationRule, (state, { rule }) => {
    const rules = { ...state.rules };
    rules[rule.id] = rule; // id must be Infinity;
    return {
      ...state,
      rules,
    };
  }),
  on(updateClassificationRule, (state, { id, rule }) => {
    const rules = { ...state.rules };
    rules[id] = rule;
    return {
      ...state,
      rules,
    };
  }),
  on(deleteClassificationRule, (state, { id }) => {
    const rules = { ...state.rules };
    delete rules[id];
    return {
      ...state,
      rules,
    };
  }),
  on(setClassificationTotalPages, (state, { totalPages }) => {
    return {
      ...state,
      totalPages,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return classificationRuleReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('rule');
export const getClassificationRules = createSelector(selectFeature, (state: State) => {
  if (state.rules === null) {
    return null;
  }
  return Object.values(state.rules).sort((a, b) => b.id - a.id);
});
export const getTotalPages = createSelector(selectFeature, (state: State) => {
  return state.totalPages;
});
