import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, from, throwError } from 'rxjs';
import { map, mergeMap, retryWhen, tap, delay, catchError } from 'rxjs/operators';
import { mapKeys, camelCase } from 'lodash';
import { environment } from '@environment';
import { SessionService } from '@/services';

function normalizeKeys(obj, kind = 'camel') {
  if (obj && obj.constructor === Object) {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      delete obj[key];
      if (kind === 'camel') {
        key = camelCase(key);
      }
      obj[key] = normalizeKeys(value, kind);
    });
  } else if (obj && obj.constructor === Array) {
    obj = obj.map(o => normalizeKeys(o, kind));
  }
  return obj;
}

@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      mergeMap(event => {
        if (
          event instanceof HttpResponse &&
          this.isInternalApi(req.url) &&
          !req.headers.has('x-meta-no-change-resource')
        ) {
          const keepOriginalKeyName = req.headers.has('x-meta-respect-original-key-name');

          let body = event.body;
          if (!keepOriginalKeyName && body) {
            body = this.toCamelCase(body);
          }
          return of(event.clone({ body }));
        } else {
          return of(event);
        }
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private toCamelCase(object) {
    return normalizeKeys(object);
  }

  private isInternalApi(url: string): boolean {
    // NOTE: cannot contain hostname in url excluding hostname
    return url.includes(environment.api.host);
  }
}
