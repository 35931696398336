import { CompanyDepartmentApi } from '@/apis';
import { CompanyDepartment } from '@/models';
import { AppState } from '@/store';
import {
  addCompanyDepartment,
  deleteCompanyDepartment,
  setCompanyDepartments,
  setCompanyDepartmentTotalPages,
  updateCompanyDepartment,
} from '@/store/company-department.store';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class CompanyDepartmentService {
  constructor(private store: Store<AppState>, private api: CompanyDepartmentApi) {}

  getCompanyDepartments(page: number = 1, perPage?: number): Observable<CompanyDepartment[]> {
    return this.api.getCompanyDepartments(page, perPage).pipe(
      tap(res => {
        const companyDepartments = res.body;
        const totalPages = parseInt(res.headers.get('total-pages'), 10);
        this.store.dispatch(setCompanyDepartments({ companyDepartments }));
        this.store.dispatch(setCompanyDepartmentTotalPages({ totalPages }));
      }),
      map(res => res.body)
    );
  }

  addCompanyDepartmentTemporary(companyDepartment: CompanyDepartment): void {
    this.store.dispatch(addCompanyDepartment({ companyDepartment }));
  }

  saveCompanyDepartment(id: number, companyDepartmentOnForm: CompanyDepartment): Observable<CompanyDepartment> {
    if (id === Infinity) {
      // Infinity means new temporary company department
      return this.api.addCompanyDepartment(companyDepartmentOnForm).pipe(
        tap(companyDepartment => {
          this.store.dispatch(deleteCompanyDepartment({ id }));
          this.store.dispatch(addCompanyDepartment({ companyDepartment }));
        })
      );
    }
    return this.api.saveCompanyDepartment(id, companyDepartmentOnForm).pipe(
      tap(companyDepartment => {
        this.store.dispatch(updateCompanyDepartment({ id, companyDepartment }));
      })
    );
  }

  deleteCompanyDepartment(id: number): Observable<void> {
    if (id === Infinity) {
      // Infinity means new temporary company department
      this.store.dispatch(deleteCompanyDepartment({ id }));
      return of();
    }
    return this.api.deleteCompanyDepartment(id).pipe(
      tap(() => {
        this.store.dispatch(deleteCompanyDepartment({ id }));
      })
    );
  }
}
