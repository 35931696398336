import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { CompanyDepartment } from '@/models';

export const setCompanyDepartmentTotalPages = createAction(
  '[CompanyDepartment] Set company department total pages',
  props<{ totalPages: number }>()
);
export const setCompanyDepartments = createAction(
  '[CompanyDepartment] Set company departments',
  props<{ companyDepartments: CompanyDepartment[] }>()
);
export const addCompanyDepartment = createAction(
  '[CompanyDepartment] Add company department',
  props<{ companyDepartment: CompanyDepartment }>()
);
export const updateCompanyDepartment = createAction(
  '[CompanyDepartment] Update company department',
  props<{ id: number; companyDepartment: CompanyDepartment }>()
);
export const deleteCompanyDepartment = createAction(
  '[CompanyDepartment] Delete company department',
  props<{ id: number }>()
);

export interface State {
  companyDepartments: { [id: number]: CompanyDepartment };
  totalPages: number;
}

export const initialState = {
  companyDepartments: null,
  totalPages: null,
};

export const companyDepartmentReducer = createReducer(
  initialState,
  on(setCompanyDepartmentTotalPages, (state, { totalPages }) => {
    return {
      ...state,
      totalPages,
    };
  }),
  on(setCompanyDepartments, (state, { companyDepartments }) => {
    const newCompanyDepartments = {};
    companyDepartments.forEach(companyDepartment => {
      newCompanyDepartments[companyDepartment.id] = companyDepartment;
    });
    return {
      ...state,
      companyDepartments: newCompanyDepartments,
    };
  }),
  on(addCompanyDepartment, (state, { companyDepartment }) => {
    const companyDepartments = { ...state.companyDepartments };
    companyDepartments[companyDepartment.id] = companyDepartment;
    return {
      ...state,
      companyDepartments,
    };
  }),
  on(updateCompanyDepartment, (state, { id, companyDepartment }) => {
    const companyDepartments = { ...state.companyDepartments };
    companyDepartments[id] = companyDepartment;
    return {
      ...state,
      companyDepartments,
    };
  }),
  on(deleteCompanyDepartment, (state, { id }) => {
    const companyDepartments = { ...state.companyDepartments };
    delete companyDepartments[id];
    return {
      ...state,
      companyDepartments,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return companyDepartmentReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('companyDepartment');
export const getCompanyDepartments = createSelector(selectFeature, (state: State) => {
  if (state.companyDepartments === null) {
    return null;
  }
  return Object.values(state.companyDepartments).sort((a, b) => b.id - a.id);
});
export const getTotalPages = createSelector(selectFeature, (state: State) => {
  return state.totalPages;
});
