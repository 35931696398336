import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@/directives/directives.module';
import { PipesModule } from '@/pipes/pipes.module';

import { MainLayoutComponent } from './main-layout/main-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { MobileLayoutComponent } from './mobile-layout/mobile-layout.component';

@NgModule({
  declarations: [MainLayoutComponent, AuthLayoutComponent, MobileLayoutComponent],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, IonicModule, DirectivesModule, PipesModule],
  exports: [MainLayoutComponent, AuthLayoutComponent, MobileLayoutComponent],
})
export class LayoutsModule {}
