import { Component, OnInit, NgZone } from '@angular/core';

type MobileLayoutTabsType = 'dashboard' | 'group-management';

@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent implements OnInit {
  currentTab: MobileLayoutTabsType = 'dashboard';

  constructor(private zone: NgZone) {}
  ngOnInit() {}

  switchTab(e) {
    this.zone.run(() => {
      this.currentTab = e.tab;
    });
  }
}
