import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { retryWhen, concatMap } from 'rxjs/operators';
import { throwError, timer, iif } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RetryInterceptor implements HttpInterceptor {
  maxRetryCount = 3;
  initialInterval = 3000;

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.method === 'GET') {
      return next
        .handle(req)
        .pipe(
          retryWhen(errors =>
            errors.pipe(
              concatMap((error, i) =>
                iif(
                  () => i < this.maxRetryCount && this.shouldRetry(error),
                  timer(Math.pow(2, i) * this.initialInterval),
                  throwError(error)
                )
              )
            )
          )
        );
    }
    return next.handle(req);
  }

  shouldRetry(error: HttpErrorResponse) {
    return error.status < 400 || error.status === 500;
  }
}
