import { NgModule } from '@angular/core';

import { BgImgDirective } from './bg-img/bg-img.directive';

@NgModule({
  declarations: [BgImgDirective],
  imports: [],
  exports: [BgImgDirective],
})
export class DirectivesModule {}
