import { Inject, Injectable, InjectionToken } from '@angular/core';
import { GuardsCheckEnd, Router } from '@angular/router';

// import { WINDOW_REF } from '../providers/window';

declare var window: any;

export const WINDOW_REF = new InjectionToken<any>('windowObject', {
  providedIn: 'root',
  factory: () => window,
});

// デフォルトのGoogleAnalyticsイベント
// https://developers.google.com/analytics/devguides/collection/gtagjs/events?hl=ja#default_google_analytics_events
export type EventCategory = 'engagement' | 'ecommerce';
export type DefaultEventType = 'sign_up' | 'add_payment_info';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  analyticsId = 'UA-178729977-2';

  constructor(private router: Router, @Inject(WINDOW_REF) private windowRef: any) {}

  initialize() {
    if (this.analyticsId) {
      this.windowRef.dataLayer = this.windowRef.dataLayer || [];
      this.loadGaScript();
      this.gtag('js', new Date());
      this.router.events.subscribe(e => {
        if (e instanceof GuardsCheckEnd) {
          if (e.shouldActivate) {
            this.sendPageView(e.url);
          }
        }
      });
    }
  }

  sendPageView(url: string) {
    this.gtag('config', this.analyticsId, { page_path: url });
  }

  sendEvent(type: DefaultEventType) {
    this.gtag('event', type);
  }

  sendCustomEvent(type: string, category?: EventCategory) {
    this.gtag('event', type, { event_category: category });
  }

  private gtag(...args) {
    this.windowRef.dataLayer.push(arguments);
  }

  private loadGaScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this.analyticsId}`);
    script.onerror = err => {
      throw err;
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
