import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { groupBy, uniq, map as _map, range, mapKeys, keyBy } from 'lodash';
import { map, mergeMap } from 'rxjs/operators';
import { CategoryApi } from './category.api';
import { combineLatest, Observable, forkJoin } from 'rxjs';
import { EChartOption } from 'echarts';
import {
  WorkSummary,
  AppTime,
  WorkTime,
  WorkSummaryBase,
  DateRange,
  RankingBase,
  WorkLogDetail,
} from '@/models/work-log';
import { moment, generateDateSeries } from '@/libs/moment';
import { HolidayApi } from './holiday.api';
import * as _ from 'lodash';
import { UserApi } from './user';
import { CategoryService } from '@/services';

@Injectable({ providedIn: 'root' })
export class WorkLogApi {
  constructor(
    private http: HttpClient,
    private categoryApi: CategoryService,
    private holidayApi: HolidayApi,
    private userApi: UserApi
  ) {
    this.categoryApi.getCategories().subscribe();
  }

  getLogsDaily(userId: string, dateRange: DateRange) {
    const params = {
      userId: userId + '',
      beginDate: dateRange.begin.format('YYYY-MM-DD'),
      endDate: dateRange.end.format('YYYY-MM-DD'),
    };

    return this.http.get<WorkSummary[]>('logs/daily', { params });
  }

  processLogsDaily(userId: string, dateRange: DateRange) {
    return combineLatest([
      this.categoryApi.getCategoriesWithKeys(),
      this.getLogsDaily(userId, dateRange),
      this.holidayApi.list(dateRange),
    ]).pipe(
      map(([categories, logs, holidays]) => {
        // TODO: ここをrange.beginからendまでの系列にする
        // const times = range(1, 31);
        const times = generateDateSeries(dateRange.begin, dateRange.end.clone().endOf('day'));
        // 休日のマーク
        // const days = times.map(d => moment(date).add(d - 1, 'days'));
        // const markers = days.filter(day => holidays[parseInt(day.format('YYYYMMDD'), 10)])
        //   .map(day => {
        //     return [
        //       { xAxis: day.date() - 1 },
        //       { xAxis: day.date() }
        //     ];
        //   });

        const groups = groupBy(logs, 'appCategoryId');

        let series = _map(groups, (v, k: number) => {
          const group = groupBy(v, 'recordedAt');
          const gs = mapKeys(group, (_2, sdate) => moment(sdate).format('YYYY-MM-DD'));
          const category = categories[k];
          const param: EChartOption.SeriesBar = {
            name: category?.name || 'その他',
            type: 'bar',
            stack: 'one',
            data: _map(times, d =>
              gs[d.format('YYYY-MM-DD')]
                ? _.chain(gs[d.format('YYYY-MM-DD')])
                    .sumBy(v2 => Math.floor((v2.sum / 60) * 100) / 100)
                    .value()
                : 0
            ),
            itemStyle: {
              normal: {
                color: category?.color || 'gray',
              },
            },
            // markArea: {
            //   silent: true,
            //   data: markers as any,
            // },
          };
          return param;
        }) as EChartOption.SeriesBar[];

        // トータルの作業時間が多いカテゴリ順にソート
        series = series.sort(
          (a, b) =>
            _.chain(b.data)
              .sum()
              .value() -
            _.chain(a.data)
              .sum()
              .value()
        );

        // しきい値表示
        const _series = [
          ...series,
          {
            type: 'bar',
            stack: 'one',
            data: [],
            markLine: {
              silent: true,
              symbol: 'none',
              lineStyle: {
                width: 2,
              },
              data: [{ yAxis: 8 * 60 }],
            },
          } as any, // 型定義がおかしいっぽい
        ];

        return { times: times.map(d => d.format('M/D')), series: _series };
      })
    );
  }

  getWorkTimeTotalByUser(userId: string, drange: DateRange): Observable<WorkTime> {
    const params = {
      userId: userId + '',
      beginDate: drange.begin.format('YYYY-MM-DD'),
      endDate: drange.end.format('YYYY-MM-DD'),
    };

    return this.http
      .get<WorkTime>('work_time/total/by_user', { params })
      .pipe(
        map(t => {
          return {
            ...t,
            duration: moment.duration(t.sum, 'minutes'),
            overTimeDuration: moment.duration(t.overTimeMins, 'minutes'),
          };
        })
      );
  }

  getWorkTimeTotalByDepartment(departmentId: string, beginDate: moment.Moment, endDate: moment.Moment) {
    const params = {
      departmentId: departmentId + '',
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };

    return this.http
      .get<WorkTime>('work_time/total/by_department', { params })
      .pipe(
        map(t => {
          return {
            ...t,
            duration: moment.duration(t.sum, 'minutes'),
            overTimeDuration: moment.duration(t.overTimeMins, 'minutes'),
            averageDuration: moment.duration(t.sum / t.totalDays, 'minutes'),
            averageOverTimeDuration: moment.duration(t.overTimeMins / t.totalDays, 'minutes'),
          };
        })
      );
  }

  getAppTime(userId: string, drange: DateRange) {
    const params = {
      userId: userId + '',
      beginDate: moment(drange.begin).format('YYYY-MM-DD'),
      endDate: moment(drange.end).format('YYYY-MM-DD'),
    };

    return this.http.get<AppTime[]>('app_time/sum', { params });
  }

  processAppTime(userId: string, drange: DateRange) {
    return combineLatest([this.categoryApi.getCategoriesWithKeys(), this.getAppTime(userId, drange)]).pipe(
      map(([categories, times]) =>
        _.chain(times)
          .groupBy(a => a.appCategoryId)
          .map((data, id) => ({
            id,
            sum: _.chain(data)
              .sumBy('sum')
              .value(),
          }))
          .map(t => {
            const category = categories[t.id];

            return {
              name: category?.name || 'その他',
              value: t.sum,
              itemStyle: {
                normal: {
                  color: category?.color || 'gray',
                },
              },
            };
          })
          .value()
      )
    );
  }

  // daily
  getWorkTimeDailyByUser(dateRange: DateRange, userId: string) {
    const params = {
      userId,
      beginDate: moment(dateRange.begin).format('YYYY-MM-DD'),
      endDate: moment(dateRange.end).format('YYYY-MM-DD'),
    };

    return this.http.get<WorkSummaryBase[]>('work_time/daily/user', { params });
  }

  processWorkTimeDailyByUser(dateRange: DateRange, userId: string) {
    return this.getWorkTimeDailyByUser(dateRange, userId).pipe(
      map(logs => {
        const logsByRecordedAt = _.chain(logs)
          .map(l => ({ recordedAt: moment(l.recordedAt).format('YYYY/MM/DD'), sum: l.sum }))
          .keyBy('recordedAt')
          .value();

        // beginDate ~ endDate間の1日ごとの日付
        return generateDateSeries(dateRange.begin, dateRange.end.clone().endOf('day')).map(date => {
          const dateStr = date.format('YYYY/MM/DD');
          const l = logsByRecordedAt[dateStr];

          return {
            recordedAt: dateStr,
            sum: _.round(moment.duration(l?.sum || 0, 'minutes').asHours(), 1), // 時間単位に変換
          };
        });
      })
    );
  }

  getWorkTimeDailyByTeam(dateRange: DateRange, workId?: number, departmentId?: number) {
    const params: any = {
      beginDate: moment(dateRange.begin).format('YYYY-MM-DD'),
      endDate: moment(dateRange.end).format('YYYY-MM-DD'),
    };
    if (workId) {
      params.workId = workId;
    }
    if (departmentId) {
      params.departmentId = departmentId;
    }

    return this.http.get<WorkSummaryBase[]>('work_time/daily/team', { params });
  }

  processWorkTimeDailyByTeam(dateRange: DateRange, workId?: number, departmentId?: number) {
    return this.getWorkTimeDailyByTeam(dateRange, workId, departmentId).pipe(
      map(logs => {
        const logsByRecordedAt = _.chain(logs)
          .map(l => ({ recordedAt: moment(l.recordedAt).format('YYYY/MM/DD'), sum: l.sum }))
          .keyBy('recordedAt')
          .value();

        // beginDate ~ endDate間の1日ごとの日付
        return generateDateSeries(dateRange.begin, dateRange.end.clone().endOf('day')).map(date => {
          const dateStr = date.format('YYYY/MM/DD');
          const l = logsByRecordedAt[dateStr];

          return {
            recordedAt: dateStr,
            sum: _.round(moment.duration(l?.sum || 0, 'minutes').asHours(), 1), // 時間単位に変換
          };
        });
      })
    );
  }

  getLogsHourly(userId: number, date: moment.Moment) {
    const y = date.year();
    const m = date.month() + 1;
    const d = date.date();

    const params = {
      userId: userId + '',
      year: y + '',
      month: m + '',
      day: d + '',
    };

    return this.http.get<WorkSummary[]>('logs/hourly', { params });
  }

  processLogsHourly(userId: number, date: moment.Moment) {
    return combineLatest([this.categoryApi.getCategoriesWithKeys(), this.getLogsHourly(userId, date)]).pipe(
      map(([categories, logs]) => {
        const times = range(0, 24); // 違う箇所

        const groups = groupBy(logs, 'appCategoryId');

        const series = _map(groups, (v, k: number) => {
          const group = groupBy(v, 'recordedAt'); // 違う箇所
          const gs = mapKeys(group, (__, sdate) => moment(sdate).hours()); // 違う箇所

          const category = categories[k];
          const param: EChartOption.SeriesBar = {
            name: category?.name || 'その他',
            type: 'bar',
            stack: 'one',
            data: _map(times, d =>
              gs[d]
                ? _.chain(gs[d])
                    .sumBy(vv => vv.sum)
                    .value()
                : 0
            ),
            itemStyle: {
              normal: {
                color: category?.color || 'gray',
              },
            },
          };
          return param;
        }) as EChartOption.SeriesBar[];

        return { times, series, firstLog: logs[0], lastLog: logs[logs.length - 1] };
      })
    );
  }

  getLogsMinutely(userId: number, date: moment.Moment) {
    const params = {
      userId: userId + '',
      date: date.format('YYYY-MM-DD'),
    };

    return this.http.get<WorkSummary[]>('logs/minutely', { params });
  }

  getDetailLogsMinutely(userId: number, beginTime: moment.Moment, endTime: moment.Moment): Observable<WorkLogDetail[]> {
    const params = {
      userId: userId + '',
      beginTime: beginTime.format('YYYY-MM-DD HH:mm:ss'),
      endTime: endTime.format('YYYY-MM-DD HH:mm:ss'),
    };

    return combineLatest([
      this.categoryApi.getCategoriesWithKeys(),
      this.http.get<WorkLogDetail[]>('logs/minutely/detail', { params }),
    ]).pipe(
      map(([categories, logs]) => {
        return logs.map(log => ({
          ...log,
          appCategory: categories[log.appCategoryId],
        }));
      })
    );
  }

  processLogsMinutely(userId: number, date: moment.Moment) {
    return combineLatest([this.categoryApi.getCategoriesWithKeys(), this.getLogsMinutely(userId, date)]).pipe(
      map(([categories, d]) =>
        d.map(r => {
          const recordedAt = moment(r.recordedAt).startOf('minute'); // 1分単位でfloor
          const duration = 1; // 幅は1分
          const category = categories[r.appCategoryId];
          return {
            name: r.appCategoryId,
            value: [recordedAt.toDate(), recordedAt.add(duration, 'minutes').toDate(), duration, category],
            itemStyle: {
              normal: {
                color: category?.color || 'gray',
              },
            },
          };
        })
      )
    );
  }

  _getWorkTimeUserRanking(dateRange: DateRange) {
    const params: any = {
      beginDate: moment(dateRange.begin).format('YYYY-MM-DD'),
      endDate: moment(dateRange.end).format('YYYY-MM-DD'),
    };

    return this.http.get<RankingBase[]>('work_time/ranking/all', { params });
  }

  getWorkTimeUserRanking(dateRange: DateRange) {
    return this._getWorkTimeUserRanking(dateRange).pipe(
      mergeMap(data =>
        forkJoin(
          data.slice(0, 3).map(d =>
            this.userApi.get(Number(d.userId)).pipe(
              map(user => {
                return {
                  user,
                  duration: moment.duration(d.sum, 'minutes'),
                };
              })
            )
          )
        )
      )
    );
  }
}
