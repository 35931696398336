import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtToken, User } from '@/models';

@Injectable()
export class SessionApi {
  constructor(private http: HttpClient) {}

  login(tenantId: string, loginId: string, password: string): Observable<User> {
    const params = {
      tenantId,
      loginId,
      password,
    };

    return this.http.post<User>('users/login', params);
  }

  logout() {
    return this.http.delete('users/logout');
  }
}
