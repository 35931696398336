import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { PcUser } from '@/models';

export const setPcUsers = createAction('[PcUsers] SetPcUsers', props<{ pcUsers: PcUser[] }>());
export const setPcUser = createAction('[PcUsers] SetPcUsers', props<{ pcUser: PcUser }>());

export interface State {
  pcUsers: PcUser[];
}

export const initialState = {
  pcUsers: null,
};

export const pcUserReducer = createReducer(
  initialState,
  on(setPcUsers, (state, { pcUsers }) => {
    return {
      ...state,
      pcUsers,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return pcUserReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('pcUser');
export const getPcUsers = createSelector(selectFeature, (state: State) => {
  return state.pcUsers;
});
