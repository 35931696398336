import { Injectable } from '@angular/core';
import { moment, generateDateSeries } from '@/libs/moment';
import { Moment, Duration } from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { DateRange } from '@/models';
import { Location } from '@angular/common';
import * as qs from 'query-string';
import { ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class DateFilterService {
  dateRange$ = new BehaviorSubject<DateRange>({
    begin: moment().startOf('month'),
    end: moment().endOf('month'),
  });
  get dateRange(): DateRange {
    return this.dateRange$.value;
  }
  set dateRange(value) {
    this.dateRange$.next(value);
  }

  compareDateRange$ = new BehaviorSubject<DateRange>({
    begin: moment()
      .add(-1, 'month')
      .startOf('month'),
    end: moment()
      .add(-1, 'month')
      .endOf('month'),
  });
  get compareDateRange(): DateRange {
    return this.compareDateRange$.value;
  }
  set compareDateRange(value) {
    this.compareDateRange$.next(value);
  }

  isCompare$ = new BehaviorSubject<boolean>(false);
  get isCompare(): boolean {
    return this.isCompare$.value;
  }
  set isCompare(value) {
    this.isCompare$.next(value);
  }

  query: any;

  constructor(private location: Location, private route: ActivatedRoute) {
    // URLパラメータから初期パラメータをセットする
    const p = this.route.snapshot.queryParams;
    if (p.dateBegin && p.dateEnd) {
      this.dateRange = { begin: moment(p.dateBegin), end: moment(p.dateEnd) };
    }
    if (p.compareDateBegin && p.compareDateEnd) {
      this.isCompare = true;
      this.compareDateRange = { begin: moment(p.compareDateBegin), end: moment(p.compareDateEnd) };
    } else {
      this.isCompare = false;
    }

    // パラメータ変化時にURLに反映する
    combineLatest([this.dateRange$, this.compareDateRange$, this.isCompare$]).subscribe(
      ([dateRange, compareDateRange, isCompare]) => {
        this.query = {};

        if (dateRange.begin && dateRange.end) {
          this.query.dateBegin = dateRange.begin.format('YYYY-MM-DD');
          this.query.dateEnd = dateRange.end.format('YYYY-MM-DD');
        }

        if (isCompare && compareDateRange) {
          this.query.compareDateBegin = compareDateRange.begin.format('YYYY-MM-DD');
          this.query.compareDateEnd = compareDateRange.end.format('YYYY-MM-DD');
        }

        this.location.replaceState(window.location.pathname, qs.stringify(this.query));
      }
    );
  }
}
