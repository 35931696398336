import { CompanyWork } from '@/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CompanyWorkApi {
  baseUrl = 'company_works';

  constructor(private http: HttpClient) {}

  getCompanyWorks(page: number, perPage?: number) {
    const params = {
      page: `${page}`,
      ...(perPage ? { perPage: `${perPage}` } : {}),
    };
    return this.http.get<CompanyWork[]>(`${this.baseUrl}`, { params, observe: 'response' });
  }

  addCompanyWork(companyWork: CompanyWork) {
    return this.http.post<CompanyWork>(`${this.baseUrl}`, { ...companyWork });
  }

  saveCompanyWork(id: number, companyWork: CompanyWork) {
    return this.http.put<CompanyWork>(`${this.baseUrl}/${id}`, { ...companyWork });
  }

  deleteCompanyWork(id: number) {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
