import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AnalyticsService, SessionService } from './services';
import { from, Observable, of } from 'rxjs';
import { mergeMap, tap, map } from 'rxjs/operators';
import { REFRESH_TOKEN_KEY } from './config';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private sessionService: SessionService,
    private storage: Storage,
    private analytics: AnalyticsService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    from(this.platform.ready()).subscribe(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.analytics.initialize();
  }
}
