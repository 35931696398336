import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { mapKeys } from 'lodash';
import palette from 'google-palette';
import { of } from 'rxjs';
import { buildHttpHost } from '@/util';
import { environment } from '@environment';
import { updateMethod } from './classification-rule.api';

export interface Category {
  id: number;
  name: string;
  color: string;
}
export type SubCategory = Category;

@Injectable({ providedIn: 'root' })
export class CategoryApi {
  baseUrl = 'app_categories';
  subCategories;
  constructor(private http: HttpClient) {}

  getCategories() {
    return this.http.get<Category[]>(`${this.baseUrl}`, { observe: 'response' });
  }

  getCategoriesWithKeys() {
    return this.getCategories().pipe(map(d => mapKeys(d.body, (v: Category) => v.id)));
  }

  getSubCategories() {
    if (this.subCategories) {
      return of(this.subCategories);
    } else {
      return this.http.get<Category[]>(`/app_sub_categories`);
    }
  }

  getSubCategoriesWithKeys() {
    return this.getSubCategories().pipe(map(d => mapKeys(d, (v: SubCategory) => v.id)));
  }

  addCategory(category: Category) {
    return this.http.post<Category>(`${this.baseUrl}`, { ...category });
  }
  saveCategory(id, category: Category) {
    return this.http.put<Category>(`${this.baseUrl}/${id}`, { ...category });
  }
  deleteCategory(id) {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  getFileUrl() {
    return `${buildHttpHost(environment)}/${this.baseUrl}/csv`;
  }
  uploadFile(file, method: updateMethod) {
    const headers = new HttpHeaders().append('x-meta-no-change-content-type', 'true');
    const formData = new FormData();
    formData.append('file', file);
    if (method === 'overwrite') {
      return this.http.put(`${this.baseUrl}`, formData, { headers });
    } else if (method === 'add') {
      return this.http.post(`${this.baseUrl}`, formData, { headers });
    }
  }
}
