import { Injectable } from '@angular/core';
import { PcUserApi } from '@/apis';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PcUser } from '@/models';
import { setPcUsers } from '@/store/pc-user.store';
import { PcUsersRelationship } from '@/models/pc-users-relationship';
import { AppState } from '@/store';
import { Store } from '@ngrx/store';

@Injectable()
export class PcUserService {
  constructor(private api: PcUserApi, private store: Store<AppState>) {}

  getPcUsers(): Observable<PcUser[]> {
    return this.api.getPcUsers().pipe(
      tap(pcUsers => {
        this.store.dispatch(setPcUsers({ pcUsers }));
      })
    );
  }

  addPcUsersRelationship(userId: number, pcUserId: number): Observable<PcUsersRelationship> {
    return this.api.addPcUserRelationship(userId, pcUserId).pipe(
      tap(pcUsersRelationships => {
        {
        }
      })
    );
  }

  deletePcUsersRelationship(userId: number, pcUserId: number): Observable<void> {
    return this.api.deletePcUserRelationship(userId, pcUserId).pipe(
      tap(pcUsersRelationships => {
        {
        }
      })
    );
  }
}
