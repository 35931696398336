import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Platform } from '@ionic/angular';

import { UserService } from '@/services';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of, Observable, throwError, from } from 'rxjs';
import { Storage } from '@ionic/storage';
import { TOKEN_KEY } from '@/config';
import { HttpErrorResponse } from '@angular/common/http';

// '/mobile'以下は現在手動ログ機能のみで利用されるため、mobile用だけのGuardを作成
@Injectable({
  providedIn: 'root',
})
export class MobileAuthGuard implements CanActivate {
  constructor(private router: Router, private platform: Platform, private userService: UserService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.me().pipe(
      map(_ => true),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          return of(this.router.parseUrl('/mobile/auth/login'));
        }
      })
    );
  }
}
