import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppState } from '@/store';
import { WorkSituationApi } from '@/apis';
import { HttpResponse } from '@angular/common/http';
import { setWorkSituations, setWorkSituation } from '@/store/work-situation.store';

@Injectable()
export class WorkSituationService {
  constructor(private store: Store<AppState>, private api: WorkSituationApi) {}

  getWorkSituations() {
    return this.api.getWorkSituations().pipe(
      tap(workSituations => {
        this.store.dispatch(setWorkSituations({ workSituations }));
      })
    );
  }

  fetchWorkSituation(date, userId) {
    return this.api.fetchWorkSituation(date, userId).pipe(
      tap(workSituation => {
        this.store.dispatch(setWorkSituation({ workSituation }));
      })
    );
  }
}
