import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Department, Work } from '@/models/team';

@Injectable({ providedIn: 'root' })
export class TeamApi {
  constructor(private http: HttpClient) {}

  listWorks() {
    return this.http.get<Work[]>('company_works');
  }

  listDepartments() {
    return this.http.get<Department[]>('company_departments');
  }
}
