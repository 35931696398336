import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClassificationRule } from '@/models';
import { environment } from '@environment';
import { buildHttpHost } from '@/util';

export type updateMethod = 'add' | 'overwrite';

@Injectable({ providedIn: 'root' })
export class ClassificationRuleApi {
  baseUrl = 'app_classification_rules';

  constructor(private http: HttpClient) {}

  getClassificationRules(page: number) {
    const params = {
      page: `${page}`,
    };
    return this.http.get<ClassificationRule[]>(`${this.baseUrl}`, { params, observe: 'response' });
  }

  addClassificationRule(rule: ClassificationRule) {
    return this.http.post<ClassificationRule>(`${this.baseUrl}`, { ...rule });
  }
  saveClassificationRule(id: number, rule: ClassificationRule) {
    return this.http.put<ClassificationRule>(`${this.baseUrl}/${id}`, { ...rule });
  }
  deleteClassificationRule(id: number) {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
  getFileUrl() {
    return `${buildHttpHost(environment)}/${this.baseUrl}/csv`;
  }
  uploadFile(file, method: updateMethod) {
    const headers = new HttpHeaders().append('x-meta-no-change-content-type', 'true');
    const formData = new FormData();
    formData.append('file', file);
    if (method === 'overwrite') {
      return this.http.put(`${this.baseUrl}`, formData, { headers });
    } else if (method === 'add') {
      return this.http.post(`${this.baseUrl}`, formData, { headers });
    }
  }
}
