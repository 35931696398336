export const PAGE_SIZE = 20;
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const TOKEN_KEY = 'token';
export const MOMENTJS_RANGE_DATE_FORMAT = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY年MM月DD日(dd)',
    monthYearLabel: 'YYYY年MM月',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY年MM月',
  },
};
