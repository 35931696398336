import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppState } from '@/store';
import { ClassificationRuleApi } from '@/apis';
import { ClassificationRule } from '@/models';
import {
  setClassificationRules,
  deleteClassificationRule,
  addClassificationRule,
  updateClassificationRule,
  setClassificationTotalPages,
} from '@/store/classification-rule.store';
import { HttpResponse } from '@angular/common/http';
import { updateMethod } from '@/apis/classification-rule.api';

@Injectable()
export class ClassificationRuleService {
  totalCount = 0;

  constructor(private store: Store<AppState>, private api: ClassificationRuleApi) {}

  getRules(page: number = 1): Observable<HttpResponse<ClassificationRule[]>> {
    return this.api.getClassificationRules(page).pipe(
      tap(res => {
        const rules = res.body;
        const totalPages = parseInt(res.headers.get('total-pages'), 10);
        this.totalCount = parseInt(res.headers.get('total-count'), 10);
        this.store.dispatch(setClassificationRules({ rules }));
        this.store.dispatch(setClassificationTotalPages({ totalPages }));
      })
    );
  }

  addRuleTemporary(rule: ClassificationRule): void {
    return this.store.dispatch(addClassificationRule({ rule }));
  }

  saveRule(id: number, ruleOnForm: ClassificationRule): Observable<ClassificationRule> {
    if (id === Infinity) {
      // Infinity means new temporary rule
      return this.api.addClassificationRule(ruleOnForm).pipe(
        tap(rule => {
          this.store.dispatch(addClassificationRule({ rule }));
          this.store.dispatch(deleteClassificationRule({ id }));
        })
      );
    }
    return this.api.saveClassificationRule(id, ruleOnForm).pipe(
      tap(rule => {
        this.store.dispatch(updateClassificationRule({ id, rule }));
      })
    );
  }

  deleteRule(id: number): Observable<void> {
    if (id === Infinity) {
      // Infinity means new temporary rule
      this.store.dispatch(deleteClassificationRule({ id }));
      return of();
    }
    return this.api.deleteClassificationRule(id).pipe(
      tap(() => {
        this.store.dispatch(deleteClassificationRule({ id }));
      })
    );
  }

  getFileUrl(): string {
    return this.api.getFileUrl();
  }

  uploadFile(file, method: updateMethod) {
    return this.api.uploadFile(file, method).pipe(tap(() => {}));
  }
}
