import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';
import { normalizeKeys } from 'object-keys-normalizer';
import snakeCase from 'lodash.snakecase';

import { environment } from '@environment';
import { buildHttpHost } from '@/util';

@Injectable({ providedIn: 'root' })
export class ApiRequestInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let requestUrl;
    let headers: HttpHeaders = req.headers;
    let body = req.body;
    let params = req.params;

    if (this.isInternalApiReq(req.url)) {
      requestUrl = `${buildHttpHost(environment)}/${req.url}`;
      params = this.toSnakeCaseParams(params);
      if (['POST', 'PUT', 'PATCH'].includes(req.method)) {
        if (!headers.has('x-meta-no-change-content-type')) {
          headers = headers.set('Content-Type', 'application/json');
        }
        if (!headers.has('x-meta-no-change-request-body')) {
          body = normalizeKeys(body, 'snake');
        }
      }
      if (environment.api.basicAuth) {
        const basic64 = btoa(`${environment.api.basicUser}:${environment.api.basicPassword}`);
        headers = headers.set('Authorization', `Basic ${basic64}`);
      }
      if (environment.api.apiKey) {
        headers = headers.set('X-Api-Key', environment.api.apiKey);
      }
    } else {
      requestUrl = req.url;
    }

    const newReq = req.clone({ headers, body, url: requestUrl, params, withCredentials: true });

    return next.handle(newReq);
  }

  private isInternalApiReq(url: string): boolean {
    // NOTE: scheme が指定されている場合は外部リクエストとして処理する
    return !/(http(s)?:\/\/+)/.test(url) && !url.includes(environment.api.host);
  }
  private toSnakeCaseParams(httpParams: HttpParams) {
    return httpParams.keys().reduce((params, key) => {
      const value = params.get(key);
      return params.delete(key).set(snakeCase(key), value);
    }, httpParams);
  }
}
