import { Environment } from '@environment';

export function isTrueProperty(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();
    return val === 'true' || val === 'on' || val === '';
  }
  return !!val;
}

export function buildHttpHost(env: Environment): string {
  const scheme = env.api.ssl ? 'https' : 'http';
  const port = env.api.port !== 80 && env.api.port !== 443 ? `:${env.api.port}` : '';
  let url = `${scheme}://${env.api.host}${port}`;

  if (env.api.basePath) {
    url += `/${env.api.basePath}`;
  }

  return url;
}
