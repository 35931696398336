import { CompanyWorkApi } from '@/apis';
import { CompanyWork } from '@/models';
import { AppState } from '@/store';
import {
  addCompanyWork,
  deleteCompanyWork,
  setCompanyWorks,
  setCompanyWorkTotalPages,
  updateCompanyWork,
} from '@/store/company-work.store';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class CompanyWorkService {
  constructor(private store: Store<AppState>, private api: CompanyWorkApi) {}

  getCompanyWorks(page: number = 1, perPage?: number): Observable<CompanyWork[]> {
    return this.api.getCompanyWorks(page, perPage).pipe(
      tap(res => {
        const companyWorks = res.body;
        const totalPages = parseInt(res.headers.get('total-pages'), 10);
        this.store.dispatch(setCompanyWorks({ companyWorks }));
        this.store.dispatch(setCompanyWorkTotalPages({ totalPages }));
      }),
      map(res => res.body)
    );
  }

  addCompanyWorkTemporary(companyWork: CompanyWork): void {
    this.store.dispatch(addCompanyWork({ companyWork }));
  }

  saveCompanyWork(id: number, companyWorkOnForm: CompanyWork): Observable<CompanyWork> {
    if (id === Infinity) {
      // Infinity means new temporary company work
      return this.api.addCompanyWork(companyWorkOnForm).pipe(
        tap(companyWork => {
          this.store.dispatch(deleteCompanyWork({ id }));
          this.store.dispatch(addCompanyWork({ companyWork }));
        })
      );
    }
    return this.api.saveCompanyWork(id, companyWorkOnForm).pipe(
      tap(companyWork => {
        this.store.dispatch(updateCompanyWork({ id, companyWork }));
      })
    );
  }

  deleteCompanyWork(id: number): Observable<void> {
    if (id === Infinity) {
      // Infinity means new temporary company work
      this.store.dispatch(deleteCompanyWork({ id }));
      return of();
    }
    return this.api.deleteCompanyWork(id).pipe(
      tap(() => {
        this.store.dispatch(deleteCompanyWork({ id }));
      })
    );
  }
}
