import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Platform } from '@ionic/angular';

import { UserService } from '@/services';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of, Observable, throwError, from } from 'rxjs';
import { Storage } from '@ionic/storage';
import { TOKEN_KEY } from '@/config';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private platform: Platform, private userService: UserService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.me().pipe(
      map(_ => true),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          if (this.platform.is('mobile')) {
            return of(this.router.parseUrl('/mobile/auth/login'));
          }
          return of(this.router.parseUrl('/auth/login'));
        }
      })
    );
  }
}
