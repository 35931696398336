import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@/directives/directives.module';
import { PipesModule } from '@/pipes/pipes.module';

import { MobileButtonComponent } from './button/button.component';

@NgModule({
  declarations: [MobileButtonComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule, DirectivesModule, PipesModule],
  exports: [MobileButtonComponent],
})
export class MobileComponentsModule {}
