import { CompanyDepartment } from '@/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CompanyDepartmentApi {
  baseUrl = 'company_departments';

  constructor(private http: HttpClient) {}

  getCompanyDepartments(page: number, perPage?: number) {
    const params = {
      page: `${page}`,
      ...(perPage ? { perPage: `${perPage}` } : {}),
    };
    return this.http.get<CompanyDepartment[]>(`${this.baseUrl}`, { params, observe: 'response' });
  }

  addCompanyDepartment(companyDepartment: CompanyDepartment) {
    return this.http.post<CompanyDepartment>(`${this.baseUrl}`, { ...companyDepartment });
  }

  saveCompanyDepartment(id: number, companyDepartment: CompanyDepartment) {
    return this.http.put<CompanyDepartment>(`${this.baseUrl}/${id}`, { ...companyDepartment });
  }

  deleteCompanyDepartment(id: number) {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
