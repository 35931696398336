import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { mapKeys } from 'lodash';

import { AppState } from '@/store';
import { Category } from '@/models';
import { CategoryApi, updateMethod } from '@/apis';
import {
  setCategories,
  updateCategory,
  deleteCategory,
  addCategory,
  categoryReducer,
  getCategories,
} from '@/store/category.store';
import { HttpResponse } from '@angular/common/http';
import { CategoryManagementPageModule } from '@/pages/category-management/category-management.module';

@Injectable()
export class CategoryService {
  totalCount = 0;
  colorMap = ['8dd3c7', 'bebada', 'fb8072', '80b1d3', 'fdb462', 'b3de69', 'fccde5'];
  constructor(private store: Store<AppState>, private api: CategoryApi) {}

  getCategories(): Observable<HttpResponse<Category[]>> {
    return this.api.getCategories().pipe(
      tap(res => {
        let categories = res.body;

        categories = categories.map(category => ({
          ...category,
          color: category.color || `#${this.colorMap[category.id % this.colorMap.length]}`,
        }));
        this.store.dispatch(setCategories({ categories }));
      })
    );
  }

  getCategoriesWithKeys() {
    // return this.getCategories().pipe(map(d => mapKeys(d.body, (v: Category) => v.id)));
    return this.store.pipe(
      select(getCategories),
      map(d => mapKeys(d, (v: Category) => v.id))
    );
  }

  // getSubCategories(): Observable<Category[]> {
  //   return this.api.getSubCategories().pipe(
  //     tap(category => {
  //     })
  //   );
  // }

  addRuleTemporary(category: Category): void {
    return this.store.dispatch(addCategory({ category }));
  }
  saveCategory(id: number, categoryOnForm: Category): Observable<Category> {
    if (id === Infinity) {
      // Infinity means new temporary category
      return this.api.addCategory(categoryOnForm).pipe(
        tap(category => {
          this.store.dispatch(addCategory({ category }));
          this.store.dispatch(deleteCategory({ id }));
        })
      );
    }
    return this.api.saveCategory(id, categoryOnForm).pipe(
      tap(category => {
        if (category) {
          this.store.dispatch(updateCategory({ id, category }));
        } else {
          // 変更無しで保存した場合はレスポンスが空
          this.store.dispatch(updateCategory({ id, category: categoryOnForm }));
        }
      })
    );
  }
  deleteCategory(id: number): Observable<void> {
    if (id === Infinity) {
      // Infinity means new temporary category
      this.store.dispatch(deleteCategory({ id }));
    }
    return this.api.deleteCategory(id).pipe(
      tap(() => {
        this.store.dispatch(deleteCategory({ id }));
      })
    );
  }
  getFileUrl(): string {
    return this.api.getFileUrl();
  }

  uploadFile(file, method: updateMethod) {
    return this.api.uploadFile(file, method).pipe(tap(() => {}));
  }
}
