import { Action, props, createAction, createReducer, on } from '@ngrx/store';
import { Attendance } from '@/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const setMakingAttendance = createAction(
  '[Attendance] Set MakingAttendance',
  props<{ makingAttendance: Attendance }>()
);

export const setYesterdayAttendance = createAction(
  '[Attendance] Set YesterdayAttendance',
  props<{ yesterdayAttendance: Attendance }>()
);

export const setTodayAttendance = createAction(
  '[Attendance] Set TodayAttendance',
  props<{ todayAttendance: Attendance }>()
);

export interface State {
  makingAttendance: Attendance;
  yesterdayAttendance: Attendance;
  todayAttendance: Attendance;
}
export const initialState = {
  makingAttendance: null,
  yesterdayAttendance: null,
  todayAttendance: null,
};

export const attendanceReducer = createReducer(
  initialState,
  on(setMakingAttendance, (state, { makingAttendance }) => {
    return {
      ...state,
      makingAttendance,
    };
  }),
  on(setYesterdayAttendance, (state, { yesterdayAttendance }) => {
    return {
      ...state,
      yesterdayAttendance,
    };
  }),
  on(setTodayAttendance, (state, { todayAttendance }) => {
    return {
      ...state,
      todayAttendance,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return attendanceReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('attendance');
export const getMakingAttendance = createSelector(selectFeature, (state: State) => state.makingAttendance);
export const getYesterdayAttendance = createSelector(selectFeature, (state: State) => state.yesterdayAttendance);
export const getTodayAttendance = createSelector(selectFeature, (state: State) => state.todayAttendance);
