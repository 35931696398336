import { AuthGuard } from '@/guards/auth/auth.guard';
import { MobileAuthGuard } from './guards/auth/mobile-auth.guard';
import { AuthLayoutComponent } from '@/layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from '@/layouts/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '@environment';
import { MobileLayoutComponent } from './layouts/mobile-layout/mobile-layout.component';
import { RoleGuard } from './guards/role/role.guard';

const routes: Routes = [
  {
    path: 'mobile',
    component: MobileLayoutComponent,
    children: [
      {
        path: 'auth',
        children: [
          {
            path: 'login',
            loadChildren: () => import('./pages/mobile-pages/auth/login/login.module').then(m => m.LoginPageModule),
          },
        ],
      },
      {
        path: 'home',
        canActivate: [MobileAuthGuard],
        loadChildren: () => import('./pages/mobile-pages/home/home.module').then(m => m.MobileHomePageModule),
      },
      {
        path: 'reports',
        canActivate: [MobileAuthGuard],
        children: [
          {
            path: 'scheduled-working-hours',
            loadChildren: () =>
              import('./pages/mobile-pages/reports/scheduled-working-hours/scheduled-working-hours.module').then(
                m => m.ScheduledWorkingHoursPageModule
              ),
          },
          {
            path: 'start-condition',
            loadChildren: () =>
              import('./pages/mobile-pages/reports/start-condition/start-condition.module').then(
                m => m.StartConditionPageModule
              ),
          },
          {
            path: 'report-work-doing',
            loadChildren: () =>
              import('./pages/mobile-pages/reports/report-work-doing/report-work-doing.module').then(
                m => m.ReportWorkDoingPageModule
              ),
          },
          {
            path: 'report-work-location',
            loadChildren: () =>
              import('./pages/mobile-pages/reports/report-work-location/report-work-location.module').then(
                m => m.ReportWorkLocationPageModule
              ),
          },
          {
            path: 'report-estimate-time',
            loadChildren: () =>
              import('./pages/mobile-pages/reports/report-estimate-time/report-estimate-time.module').then(
                m => m.ReportEstimateTimePageModule
              ),
          },
          {
            path: 'report-finished',
            loadChildren: () =>
              import('./pages/mobile-pages/reports/report-finished/report-finished.module').then(
                m => m.ReportFinishedPageModule
              ),
          },
          {
            path: 'daily-work',
            loadChildren: () =>
              import('./pages/mobile-pages/reports/daily-work/daily-work.module').then(m => m.DailyWorkPageModule),
          },
          {
            path: 'daily-condition',
            loadChildren: () =>
              import('./pages/mobile-pages/reports/daily-condition/daily-condition.module').then(
                m => m.DailyConditionPageModule
              ),
          },
          {
            path: 'daily-working-hours',
            loadChildren: () =>
              import('./pages/mobile-pages/reports/daily-working-hours/daily-working-hours.module').then(
                m => m.DailyWorkingHoursPageModule
              ),
          },
          {
            path: 'daily-report-finished',
            loadChildren: () =>
              import('./pages/mobile-pages/reports/daily-report-finished/daily-report-finished.module').then(
                m => m.DailyReportFinishedPageModule
              ),
          },
        ],
      },
      { path: '**', redirectTo: 'home' },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        canActivate: [RoleGuard],
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
      },
      {
        path: 'work-summary',
        loadChildren: () => import('./pages/work-summary/work-summary.module').then(m => m.WorkSummaryModule),
      },
      {
        path: 'operating-status-daily',
        loadChildren: () =>
          import('./pages/operating-status-daily/operating-status-daily.module').then(
            m => m.OperatingStatusDailyPageModule
          ),
      },
      {
        path: 'browser-log',
        loadChildren: () => import('./pages/browser-log/browser-log.module').then(m => m.BrowserLogPageModule),
      },
      {
        path: 'company-information',
        loadChildren: () =>
          import('./pages/company-information/company-information.module').then(m => m.CompanyInformationPageModule),
      },
      {
        path: 'user-management',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/user-management/user-management.module').then(m => m.UserManagementPageModule),
          },
          {
            path: 'new',
            loadChildren: () =>
              import('./pages/user-management/user-new/user-new.module').then(m => m.UserNewPageModule),
          },
          {
            path: ':id',
            loadChildren: () =>
              import('./pages/user-management/user-detail/user-detail.module').then(m => m.UserDetailPageModule),
          },
        ],
      },
      {
        path: 'pc-management',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/pc-management/pc-management.module').then(m => m.PcManagementPageModule),
          },
        ],
      },
      {
        path: 'name-identification-condition',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/name-identification-condition/name-identification-condition.module').then(
                m => m.NameIdentificationConditionPageModule
              ),
          },
          {
            path: 'form/:id',
            loadChildren: () =>
              import(
                './pages/name-identification-condition/name-identification-condition-form/name-identification-condition-form.module'
              ).then(m => m.NameIdentificationConditionFormPageModule),
          },
        ],
      },
      {
        path: 'name-identification-history',
        loadChildren: () =>
          import('./pages/name-identification-history/name-identification-history.module').then(
            m => m.NameIdentificationHistoryPageModule
          ),
      },
      {
        path: 'company-department-management',
        loadChildren: () =>
          import('./pages/company-department-management/company-department-management.module').then(
            m => m.CompanyDepartmentManagementPageModule
          ),
      },
      {
        path: 'company-work-management',
        loadChildren: () =>
          import('./pages/company-work-management/company-work-management.module').then(
            m => m.CompanyWorkManagementPageModule
          ),
      },
      {
        path: 'rule-management',
        loadChildren: () =>
          import('./pages/rule-management/rule-management.module').then(m => m.RuleManagementPageModule),
      },
      {
        path: 'category-management',
        loadChildren: () =>
          import('./pages/category-management/category-management.module').then(m => m.CategoryManagementPageModule),
      },
      {
        path: 'alert-management',
        loadChildren: () =>
          import('./pages/alert-management/alert-management.module').then(m => m.AlertManagementPageModule),
      },
      {
        path: 'group-management',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/group-management/group-management.module').then(m => m.GroupManagementPageModule),
          },
          {
            path: ':id',
            loadChildren: () =>
              import('./pages/group-management/group-detail/group-detail.module').then(m => m.GroupDetailPageModule),
          },
          {
            path: 'form/:id',
            loadChildren: () =>
              import('./pages/group-management/group-form/group-form.module').then(m => m.GroupFormPageModule),
          },
        ],
      },
      {
        path: 'daily-report',
        loadChildren: () => import('./pages/daily-report/daily-report.module').then(m => m.DailyReportPageModule),
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule),
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule),
      },
      {
        path: 'password-reset',
        loadChildren: () =>
          import('./pages/auth/password-reset/password-reset.module').then(m => m.PasswordResetPageModule),
      },
      {
        path: 'applied',
        loadChildren: () => import('./pages/auth/applied/applied.module').then(m => m.AppliedPageModule),
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

// dev.twogate.net向けにreload対応
const routerOption = Object.assign({ preloadingStrategy: PreloadAllModules, paramsInheritanceStrategy: 'always' });

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOption)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
