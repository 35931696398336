import { User } from '@/models';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

export const SET_CURRENT_USER = '[User] SetCurrentUser';
export const SELECTED_USER = '[User] SelectedUser';

export class SetCurrentUser implements Action {
  readonly type = SET_CURRENT_USER;
  constructor(public user: User) {}
}
export class SelectedUser implements Action {
  readonly type = SELECTED_USER;
  constructor(public user: User) {}
}

export const setCurrentUser = createAction('[User] Set current user', props<{ currentUser: User }>());
export const setSelectedUser = createAction('[User] Set selected user', props<{ selectedUser: User }>());
export const addUser = createAction('[User] Add user', props<{ user: User }>());
export const setUsers = createAction('[User] Set users', props<{ users: User[] }>());
export const setUserTotalPages = createAction('[User] Set user total pages', props<{ totalPages: number }>());
export const updateUser = createAction('[User] Update user', props<{ id: number; user: User }>());
export const deleteUser = createAction('[User] Delete user', props<{ id: number }>());

export type All = SetCurrentUser | SelectedUser;

export interface State {
  users: { [id: number]: User };
  currentUser: User;
  selectedUser: User;
  totalPages: number;
}
export const initialState = {
  users: null,
  currentUser: null,
  selectedUser: null,
  totalPages: null,
};

export const userReducer = createReducer(
  initialState,
  on(setCurrentUser, (state, { currentUser }) => {
    return {
      ...state,
      currentUser,
    };
  }),
  on(setSelectedUser, (state, { selectedUser }) => {
    return {
      ...state,
      selectedUser,
    };
  }),
  on(addUser, (state, { user }) => {
    const users = { ...state.users };
    users[user.id] = user; // id must be Infinity;
    return {
      ...state,
      users,
    };
  }),
  on(setUsers, (state, { users }) => {
    const newUsers = {};
    users.forEach(user => {
      newUsers[user.id] = user;
    });
    return {
      ...state,
      users: newUsers,
    };
  }),
  on(setUserTotalPages, (state, { totalPages }) => {
    return {
      ...state,
      totalPages,
    };
  }),
  on(updateUser, (state, { id, user }) => {
    const users = { ...state.users };
    users[id] = user;
    return {
      ...state,
      users,
    };
  }),
  on(deleteUser, (state, { id }) => {
    const users = { ...state.users };
    delete users[id];
    return {
      ...state,
      users,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('user');
export const getCurrentUser = createSelector(selectFeature, (state: State) => state.currentUser);
export const getSelectedUser = createSelector(selectFeature, (state: State) => state.selectedUser);
export const getUsers = createSelector(selectFeature, (state: State) => {
  if (state.users === null) {
    return null;
  }
  return Object.values(state.users).sort((a, b) => b.id - a.id);
});
export const getTotalPages = createSelector(selectFeature, (state: State) => {
  return state.totalPages;
});
