import { NgModule } from '@angular/core';
import { CategoryApi } from './category.api';
import { ClassificationRuleApi } from './classification-rule.api';
import { CompanyDepartmentApi } from './company-department.api';
import { CompanyWorkApi } from './company-work.api';
import { EditorImageApi } from './editor-image';
import { HolidayApi } from './holiday.api';
import { PcUserApi } from './pc-user.api';
import { SessionApi } from './session';
import { TeamApi } from './team.api';
import { TenantApi } from './tenant.api';
import { UploadApi } from './upload';
import { UserApi } from './user';
import { WorkLogApi } from './work-log.api';
import { LaborReportApi } from './labor-report.api';
import { AttendanceApi } from './attendance.api';
import { WorkSituationApi } from './work-situation.api';
import { WorkTimeApi } from './work-time.api';

@NgModule({
  providers: [
    SessionApi,
    UploadApi,
    EditorImageApi,
    UserApi,
    CategoryApi,
    WorkLogApi,
    HolidayApi,
    TeamApi,
    TenantApi,
    ClassificationRuleApi,
    CompanyDepartmentApi,
    CompanyWorkApi,
    PcUserApi,
    LaborReportApi,
    AttendanceApi,
    WorkSituationApi,
    WorkTimeApi,
  ],
})
export class ApisModule {}
