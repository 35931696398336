import { UserApi, updateMethod } from '@/apis';
import { User } from '@/models';
import { AppState } from '@/store';
import {
  setUsers,
  setUserTotalPages,
  updateUser,
  setSelectedUser,
  addUser,
  deleteUser,
  setCurrentUser,
} from '@/store/user.store';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormService } from './form.service';

@Injectable()
export class UserService {
  constructor(private store: Store<AppState>, private api: UserApi, private formService: FormService) {}

  getUser(userId: number): Observable<User> {
    return this.api.get(userId).pipe(
      tap(user => {
        this.store.dispatch(setSelectedUser({ selectedUser: user }));
      })
    );
  }

  getUsers(page: number = 1, workId?: number, departmentId?: number): Observable<HttpResponse<User[]>> {
    return this.api.getUsers(page, workId, departmentId).pipe(
      tap(res => {
        const users = res.body;
        const totalPages = parseInt(res.headers.get('total-pages'), 10);
        this.store.dispatch(setUsers({ users }));
        this.store.dispatch(setUserTotalPages({ totalPages }));
      })
    );
  }

  me() {
    return this.api.me().pipe(tap(u => this.store.dispatch(setCurrentUser({ currentUser: u }))));
  }

  addUser(userOnForm: User): Observable<User> {
    return this.api.addUser(userOnForm).pipe(
      tap(user => {
        this.store.dispatch(addUser({ user }));
      })
    );
  }
  updateUser(id: number, userOnForm): Observable<User> {
    return this.api.updateUser(id, userOnForm).pipe(
      tap(user => {
        this.store.dispatch(updateUser({ id, user }));
      })
    );
  }
  updateLoginInfo(id: number, userOnForm): Observable<User> {
    return this.api.updateLoginInfo(id, userOnForm).pipe(
      tap(user => {
        this.store.dispatch(updateUser({ id, user }));
      })
    );
  }
  deleteUser(id): Observable<void> {
    return this.api.deleteUser(id).pipe(
      tap(() => {
        this.store.dispatch(deleteUser({ id }));
      })
    );
  }

  getFileUrl(): string {
    return this.api.getFileUrl();
  }
  uploadFile(file, method: updateMethod) {
    return this.api.uploadFile(file, method).pipe(tap(() => {}));
  }
}
