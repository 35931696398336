import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppState } from '@/store';
import { setYesterdayAttendance, setTodayAttendance } from '@/store/attendance.store';
import { AttendanceApi } from '@/apis';
import { Attendance } from '@/models';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class AttendanceService {
  constructor(private store: Store<AppState>, private api: AttendanceApi) {}

  createAttendance(attendance): Observable<Attendance> {
    return this.api.createAttendance(attendance);
  }

  todayAttendance(): Observable<Attendance> {
    return this.api.todayAttendance().pipe(
      tap(attendance => {
        this.store.dispatch(setTodayAttendance({ todayAttendance: attendance }));
      })
    );
  }

  yesterdayAttendance(): Observable<Attendance> {
    return this.api.yesterdayAttendance().pipe(
      tap(attendance => {
        this.store.dispatch(setYesterdayAttendance({ yesterdayAttendance: attendance }));
      })
    );
  }

  updateAttendance(attendanceId, attendance): Observable<Attendance> {
    return this.api.updateAttendance(attendanceId, attendance).pipe(
      tap(attendance => {
        this.store.dispatch(setTodayAttendance({ todayAttendance: attendance }));
      })
    );
  }

  fetchAttendances(userId, range) {
    return this.api.fetchAttendances(userId, range);
  }
}
