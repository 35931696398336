import { NgModule } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import { CategoryService } from './category.service';
import { ClassificationRuleService } from './classification-rule.service';
import { CompanyDepartmentService } from './company-department.service';
import { CompanyWorkService } from './company-work.service';
import { DateFilterService } from './date-filter.service';
import { EditorImageService } from './editor-image.service';
import { FormService } from './form.service';
import { PcUserService } from './pc-user.service';
import { SessionService } from './session.service';
import { UploadService } from './upload.service';
import { UserService } from './user.service';
import { LaborReportService } from './labor-report.service';
import { AttendanceService } from './attendance.service';
import { WorkSituationService } from './work-situation.service';

@NgModule({
  providers: [
    SessionService,
    DateFilterService,
    FormService,
    UploadService,
    EditorImageService,
    UserService,
    ClassificationRuleService,
    CompanyDepartmentService,
    CategoryService,
    CompanyWorkService,
    PcUserService,
    AnalyticsService,
    LaborReportService,
    AttendanceService,
    WorkSituationService,
  ],
})
export class ServicesModule {}
