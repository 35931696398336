import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class EditorImageApi {
  constructor(private http: HttpClient) {}

  issuePolicy(originalName: string, size: number, contentType: string): Observable<any> {
    const url = `editor_images/publish_policy`;
    return this.http.post(
      url,
      { medium: { original_name: originalName, size, content_type: contentType } },
      { headers: new HttpHeaders().set('x-meta-respect-original-key-name', '') }
    );
  }
}
