import { CompanyWork } from '@/models';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

export const setCompanyWorkTotalPages = createAction(
  '[CompanyWork] Set company work total pages',
  props<{ totalPages: number }>()
);
export const setCompanyWorks = createAction(
  '[CompanyWork] Set company works',
  props<{ companyWorks: CompanyWork[] }>()
);
export const addCompanyWork = createAction('[CompanyWork] Add company work', props<{ companyWork: CompanyWork }>());
export const updateCompanyWork = createAction(
  '[CompanyWork] Update company work',
  props<{ id: number; companyWork: CompanyWork }>()
);
export const deleteCompanyWork = createAction('[CompanyWork] Delete company work', props<{ id: number }>());

export interface State {
  companyWorks: { [id: number]: CompanyWork };
  totalPages: number;
}

export const initialState = {
  companyWorks: null,
  totalPages: null,
};

export const companyWorkReducer = createReducer(
  initialState,
  on(setCompanyWorkTotalPages, (state, { totalPages }) => {
    return {
      ...state,
      totalPages,
    };
  }),
  on(setCompanyWorks, (state, { companyWorks }) => {
    const newCompanyWorks = {};
    companyWorks.forEach(companyWork => {
      newCompanyWorks[companyWork.id] = companyWork;
    });
    return {
      ...state,
      companyWorks: newCompanyWorks,
    };
  }),
  on(addCompanyWork, (state, { companyWork }) => {
    const companyWorks = { ...state.companyWorks };
    companyWorks[companyWork.id] = companyWork;
    return {
      ...state,
      companyWorks,
    };
  }),
  on(updateCompanyWork, (state, { id, companyWork }) => {
    const companyWorks = { ...state.companyWorks };
    companyWorks[id] = companyWork;
    return {
      ...state,
      companyWorks,
    };
  }),
  on(deleteCompanyWork, (state, { id }) => {
    const companyWorks = { ...state.companyWorks };
    delete companyWorks[id];
    return {
      ...state,
      companyWorks,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return companyWorkReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('companyWork');
export const getCompanyWorks = createSelector(selectFeature, (state: State) => {
  if (state.companyWorks === null) {
    return null;
  }
  return Object.values(state.companyWorks).sort((a, b) => b.id - a.id);
});
export const getTotalPages = createSelector(selectFeature, (state: State) => {
  return state.totalPages;
});
