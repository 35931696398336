// Production Environments
import { Environment } from './environment.type';

export const environment: Environment = {
  name: 'production',
  production: true,

  api: {
    host: 'prod.api.proto.hatarepo.com',
    port: 443,
    ssl: true,
    apiKey: 'Qfnn3GF4Ij74VQPCS4ZNx7hJLOoR1iTm8L1dxyLX',
  },
};

export * from './environment.type';
