import { ActionReducerMap } from '@ngrx/store';
import * as fromCategory from './category.store';
import * as fromRules from './classification-rule.store';
import * as fromCompanyDepartment from './company-department.store';
import * as fromWork from './company-work.store';
import * as fromPcUser from './pc-user.store';
import * as fromUser from './user.store';
import * as fromLaborReport from './labor-report.store';
import * as fromAttendance from './attendance.store';
import * as fromWorkSituation from './work-situation.store';

export interface AppState {
  user: fromUser.State;
  rule: fromRules.State;
  companyDepartment: fromCompanyDepartment.State;
  category: fromCategory.State;
  companyWork: fromWork.State;
  pcUser: fromPcUser.State;
  laborReport: fromLaborReport.State;
  attendance: fromAttendance.State;
  workSituation: fromWorkSituation.State;
}

export const reducers: ActionReducerMap<AppState> = {
  user: fromUser.reducer,
  rule: fromRules.reducer,
  companyDepartment: fromCompanyDepartment.reducer,
  category: fromCategory.reducer,
  companyWork: fromWork.reducer,
  pcUser: fromPcUser.reducer,
  laborReport: fromLaborReport.reducer,
  attendance: fromAttendance.reducer,
  workSituation: fromWorkSituation.reducer,
};

export { metaReducers } from './meta';
